import { useNavigate } from "react-router-dom";
import { routes } from "../../Constants/routes";

interface Props {
    target: string;
}

export default function CompletePage(target: Props) {
    const navigate = useNavigate()

    return (
        <div className="d-flex flex-column align-items-center pt-64" style={{ height: "600px" }}>
            <div className="pb-32">
                <h1>Nu är du nästan klar!</h1>
            </div>
            <div className="text-center mb-32">
                <h2>Ta en kopp kaffe (eller te om du gillar det bättre) så passar vi på att flytta din data till {target} under tiden. <br />Du kan enkelt kolla hur det går under Status och skulle vi behöva veta något mer om dig så får du en notis där! </h2>
            </div>
            <div className="pb-128 d-flex align-items-center flex-column">
                <div className="nav nav-pills nav-pills-primary mb-32">
                    <button type="button" className="active p-24" onClick={() => navigate(routes.summarizedList)}><p className="font-20">Till Status</p></button>
                </div>
                {/* <div className="pb-8">
                    <p className="font-family-ubuntu-semibold">Psst. Du vet väl om att du alltid kan höra av dig till flytthjälpen på 010-458 44 80 om du har några frågor. <a href={"https://vismaspcs.se/kontakta-oss#support"} target="_blank">Se våra öppettider här.</a></p>
                </div> */}
            </div>
        </div>
    )
}