import { useHelpers } from "../../../Utils/helpers";
import { ErrorsAndWarnings, InternalMigration } from "../../Reducers/internalMigrationReducer";
import { LogType } from "../Enums/LogType";
import React, { useEffect, useState } from "react";
import Modal from "../Helpers/Modal";
import { onToggleModal } from "../../Reducers/modalReducer";
import { modalTypes } from "../../Constants/modalTypes";
import { useAppDispatch, useAppSelector } from "../../hooks";

export default function MigrationLog(props: { migration: InternalMigration }) {
    const helpers = useHelpers();

    const [logs, setLogsState] = useState([] as ErrorsAndWarnings[]);
    const [filters, setFilters] = useState([LogType.Error, LogType.Warning, LogType.Info]);
    const [detailsLog, setActiveDetailsLog] = useState(null as ErrorsAndWarnings | null);

    // Just to prevent null reference
    const allLogs = props.migration.logs && props.migration.logs.length > 0 ? props.migration.logs : []

    const dispatch = useAppDispatch();

    const activeModal = useAppSelector((state) => state.modal.modal);

    useEffect(() => {
        props.migration.logs && filterLogs();
    }, [props.migration.logs]);

    const filterLogs = () => {
        var filtered = allLogs.filter((item) =>  filters.some(f => f == item.logType));
        setLogsState(filtered)
    }

    // This is only used for the counter in the badges for each log type
    const getLogsForType = (logType: LogType) => allLogs.filter((item) => item.logType === logType)

    const setFilter = (logType: LogType) => {

        if (filters.some(x => x === logType))
            filters.splice(filters.indexOf(logType), 1)
        else
            filters.push(logType)

        setFilters(filters)
        filterLogs();
    }

    const getClassList = (logType: LogType) => filters.some(x => x === logType) ? "btn btn-secondary active" : "btn btn-secondary";

    const showDetailsModal = (logItem: ErrorsAndWarnings) => {
        dispatch(onToggleModal(modalTypes.detailsLogModal));
        setActiveDetailsLog(logItem);
    }

    return <>
        <div>
            <div className="btn-group btn-group-tabs">
                <button type="button" className={getClassList(LogType.Error)} data-toggle="tab" onClick={() => setFilter(LogType.Error)}>Fel <span className="badge badge-info">{getLogsForType(LogType.Error).length}</span></button>
                <button type="button" className={getClassList(LogType.Warning)} data-toggle="tab" onClick={() => setFilter(LogType.Warning)}>Varningar <span className="badge badge-info">{getLogsForType(LogType.Warning).length}</span></button>
                <button type="button" className={getClassList(LogType.Info)} data-toggle="tab" onClick={() => setFilter(LogType.Info)}>Info <span className="badge badge-info">{getLogsForType(LogType.Info).length}</span></button>
            </div>
        </div>

        <div>
            <div>
                <div className="table-responsive">
                    <table className="table table-stripe">
                        <thead>
                            <tr>
                                <th>Felstatus</th>
                                <th>Loggtyp</th>
                                <th>Logg meddelande</th>
                                <th>Översatt meddelande</th>
                                <th>Datum</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logs && logs.map((item:ErrorsAndWarnings, index: number) =>
                                <tr key={index} >
                                    <td>{item.occuredOnName}</td>
                                    <td>{LogType[item.logType]}</td>
                                    <td style={{display: "flex"}} className="align-items-center justify-content-between">
                                        <span>{item.message}</span>
                                        {item.details && item.details.length > 0 && <a className="btn btn-link p-0 m-0" onClick={() => showDetailsModal(item)}><i className={"pr-4 vismaicon vismaicon-dynamic vismaicon-document-info"}></i></a>}
                                    </td>
                                    <td>{item.friendlyMessage}
                                    </td>
                                    <td className="text-nowrap">{helpers.formatDateAndTime(item.dateTime, true)}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        {activeModal === modalTypes.detailsLogModal && detailsLog && <Modal title={detailsLog.message} hideButtons={true} size="modal-lg" >
            {detailsLog && <p>{detailsLog.details}</p>}
        </Modal>}
    </>
}